
import { defineComponent, onMounted, reactive, toRefs } from 'vue'
import { useRoute } from 'vue-router'

import { wuRequest } from '@/service'

import { url } from '@/service/config'

import Lb from '@/components/Lb.vue'

//import HelloWorld from '@/components/HelloWorld.vue' // @ is an alias to /src

export default defineComponent({
    components: { Lb },
    setup() {
        let t = reactive<any>({
            data: {},
            url,
            info: ''
        })
        const route = useRoute().params
        onMounted(async () => {
            // 保持菜单更新一直在顶
            document.body.scrollTop = document.documentElement.scrollTop = 0
            let res = await wuRequest.get({
                url: '/new-list/' + route.id
            })
            t.data = res.data.data.data
            t.info = t.data.new.info.replace(/src="/g, 'src="' + url)
            t.info = t.info.replace(/img /g, 'img class="w-100 mt-1 mb-1"')
        })

        return { ...toRefs(t) }
    }
})
