import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1142d097"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-0 m-0" }
const _hoisted_2 = { class: "title mt-1" }
const _hoisted_3 = { class: "row p-0 m-0" }
const _hoisted_4 = { class: "col-12 m-0 d-flex justify-content-center content" }
const _hoisted_5 = { class: "d-inline-block" }
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lb = _resolveComponent("lb")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.data.lb?.length > 0)
        ? (_openBlock(), _createBlock(_component_lb, {
            key: 0,
            lb: _ctx.data.lb
          }, null, 8, ["lb"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.data.new?.title), 1)
        ])
      ])
    ]),
    _createElementVNode("div", {
      class: "info",
      innerHTML: _ctx.info
    }, null, 8, _hoisted_6)
  ], 64))
}